import request from '@/utils/request-wxwork';

let projectCode;

// projectCode = 'WIKBCGX2103036600';

function getStorageData() {
  projectCode = sessionStorage.getItem('projectCode');
}

// 首页数据
export function indexPage(params) {
  getStorageData();
  console.log(2, sessionStorage.nonce);
  return request().get('/api/weekly/v2/salesWeeklyReport/indexPage', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 周列表
export function getWeekPeriods(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/getWeekPeriods', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 首页柱状图
export function getBarChartData(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/getBarChartData', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 省
export function provinceFind() {
  return request().get('/api/weekly/v2/lgselect/province/find');
}

// 市
export function cityFind(params) {
  return request().get('/api/weekly/v2/lgselect/city/find', { params });
}

// 活跃度详情
export function attRateDetail(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/attRateDetail', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 活跃度图表
export function attRateDetailSummary(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/attRateDetailSummary', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 激活率
export function activateRateDetail(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/activateRateDetail', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 激活率 月
export function activateRateMonthDetail(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/activateRateMonthDetail', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// 审核结果
export function auditResult(params) {
  getStorageData();
  return request().get('/api/weekly/v2/salesWeeklyReport/auditResult', {
    params: {
      projectCode,
      ...params,
    },
  });
}

// export function auditResult(params) {
//   getStorageData();
//   return request().get('/api/weekly/v2/salesWeeklyReport/taskAuditFeedbackList', {
//     params: {
//       projectCode,
//       ...params,
//     },
//   });
// }

// 审核图片统计
export function terminalImgDetailSummary(params) {
  getStorageData();
  return request().post('/api/weekly/v2/salesWeeklyReport/terminalImgDetailSummary', {
    projectCode,
    ...params,
  });
}

// export function terminalImgDetailSummary(params) {
//   getStorageData();
//   return request().get('/api/weekly/v2/salesWeeklyReport/getTerminalImgDetailSummary', {
//     params: {
//       projectCode,
//       ...params,
//     },
//   });
// }

// 审核图片详情
export function terminalImgDetail(params) {
  getStorageData();
  return request().post('/api/weekly/v2/salesWeeklyReport/terminalImgDetail', {
    projectCode,
    ...params,
  });
}
