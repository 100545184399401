import axios from 'axios';
import { Toast } from 'vant';

export default function () {
  const service = axios.create({
    headers: {
      signature: sessionStorage.getItem('signature'),
      encrypt: sessionStorage.getItem('encrypt'),
      nonce: sessionStorage.getItem('nonce'),
      timestamp: sessionStorage.getItem('timestamp'),
    },
  });

  // 请求拦截器
  service.interceptors.request.use(
    (config) => {
      // 在发送请求前做些什么
      if (!config.hideLoading) {
        Toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        });
      }
      return config;
    },
    (err) => {
      // 在请求错误的时候做些什么
      return Promise.reject(err);
    }
  );

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      // 在请求成功对响应数据做处理
      Toast.clear();
      return res;
    },
    (err) => {
      // 在响应错误的时候做些什么
      Toast.fail('服务器开个小差');
      return Promise.reject(err);
    }
  );

  return service;
}
