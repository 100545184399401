// 获取 URL 参数
const getQueryVariable = (variable) => {
  // var query = window.location.search.substring(1);
  if (location.href.indexOf('?') === -1) return null;
  let href = decodeURIComponent(location.href);
  let query = href.split('?')[1];
  query = query.split('#')[0];
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] == variable) {
      return vars[i].substr(pair[0].length + 1);
    }
  }
  return null;
};

export { getQueryVariable };
