import { createRouter, createWebHashHistory } from 'vue-router';
import WeekReport from '../views/officialAccounts/WeekReport.vue';

const routes = [
  {
    path: '/',
    name: 'WeekReport',
    component: WeekReport,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/activationRate',
    name: 'ActivationRate',
    component: () => import('../views/officialAccounts/ActivationRate.vue'),
  },
  {
    path: '/activityDetails',
    name: 'ActivityDetails',
    component: () => import('../views/officialAccounts/ActivityDetails.vue'),
  },
  {
    path: '/auditResults',
    name: 'AuditResults',
    component: () => import('../views/officialAccounts/AuditResults.vue'),
  },
  {
    path: '/auditImages',
    name: 'AuditImages',
    component: () => import('../views/officialAccounts/AuditImages.vue'),
  },
  {
    path: '/realtimedynamic',
    name: 'Realtimedynamic',
    component: () => import('../views/officialAccounts/Realtimedynamic.vue'),
  },
  {
    path: '/historicalrecords',
    name: 'Historicalrecords',
    component: () => import('../views/officialAccounts/Historicalrecords.vue'),
  },
  {
    path: '/realtimedynamicDetails',
    name: 'RealtimedynamicDetails',
    component: () => import('../views/officialAccounts/RealDetail.vue'),
  },
  {
    path: '/terminal',
    name: 'Terminal',
    component: () => import('../views/officialAccounts/Terminal.vue'),
  },
  {
    path: '/terminalPlanDetail',
    name: 'TerminalPlanDetail',
    component: () => import('../views/officialAccounts/TerminalPlanDetail.vue'),
  },
  {
    path: '/activationDetail',
    name: 'ActivationDetail',
    component: () => import('../views/officialAccounts/ActivationDetail.vue'),
  },
  {
    path: '/activeDetail',
    name: 'ActiveDetail',
    component: () => import('../views/officialAccounts/ActiveDetail.vue'),
  },
  {
    path: '/implementationDetail',
    name: 'ImplementationDetail',
    component: () => import('../views/officialAccounts/ImplementationDetail.vue'),
  },
  {
    path: '/implementationDetail2',
    name: 'ImplementationDetail2',
    component: () => import('../views/officialAccounts/ImplementationDetail2.vue'),
  },
  {
    path: '/activeStatistics',
    name: 'ActiveStatistics',
    component: () => import('../views/officialAccounts/ActiveStatistics.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
