import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "core-js/modules/es.array.push.js";
import GroupColumnarChart from '../../components/GroupColumnarChart.vue';
import { indexPage, getWeekPeriods, getBarChartData, auditResult } from '@/api/wxwork';
import dayjs from 'dayjs';
import NoMore from '../../components/NoMore.vue';
export default {
  components: {
    [_Tab.name]: _Tab,
    [_Tabs.name]: _Tabs,
    GroupColumnarChart,
    NoMore
  },
  data() {
    return {
      week: -1,
      weekList: [],
      weekMap: ['一', '二', '三', '四', '五', '六'],
      datas: {},
      chartData1: [],
      chartData2: [],
      auditResultData: {}
    };
  },
  mounted() {
    document.title = '项目周报';
    this.week = parseInt(sessionStorage.getItem('week'));
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.tabs.resize();
      }, 0);
    });
    this.getData();
    this.getWeeks();
    // this.getChartData();
    // this.getAuditResult();
  },

  methods: {
    getData() {
      indexPage({
        week: this.week
      }).then(res => {
        this.datas = res.data.data && res.data.data.length ? res.data.data[0] : {};
      });
      this.getChartData();
      this.getAuditResult();
    },
    getWeeks() {
      getWeekPeriods().then(res => {
        res.data.data.map(item => {
          item.startDate = item.startDate ? dayjs(item.startDate).format('MM.DD') : '';
          item.endDate = item.endDate ? dayjs(item.endDate).format('MM.DD') : '';
        });
        this.weekList = res.data.data;
      });
    },
    getChartData() {
      getBarChartData({
        week: this.week
      }).then(res => {
        this.chartData1 = [];
        this.chartData2 = [];
        res.data.map(item => {
          this.chartData1.unshift({
            name: '本周已激活',
            week: `第 ${item.week} 周`,
            dataCnt: item.weekActivatedTaskCnt
          });
          this.chartData1.unshift({
            name: '本月计划任务',
            week: `第 ${item.week} 周`,
            dataCnt: item.monthTaskTotalCnt
          });
          this.chartData2.unshift({
            name: '本周实际活跃次数',
            week: `第 ${item.week} 周`,
            dataCnt: item.weekFinishedAttCnt
          });
          this.chartData2.unshift({
            name: '本月预计活跃次数',
            week: `第 ${item.week} 周`,
            dataCnt: item.monthPlanAttCnt
          });
        });
        this.$refs.chart1.initChart();
        this.$refs.chart2.initChart();
      });
    },
    getAuditResult() {
      auditResult({
        week: this.week
      }).then(res => {
        console.log(res);
        this.auditResultData = res.data.data && res.data.data.length ? res.data.data[0] : {};
      });
    },
    goActivation(isWeek) {
      this.$router.push({
        path: '/activationRate',
        query: {
          isWeek,
          week: this.week,
          total: this.datas.monthTaskTotalCnt,
          activated: this.datas.monthActivatedTaskCnt,
          noActivated: this.datas.monthNoactivatedTaskCnt
        }
      });
    },
    goActivityDetail() {
      this.$router.push({
        path: '/activityDetails',
        query: {
          week: this.week
        }
      });
    },
    formatPersent(num) {
      return parseFloat((num * 100).toFixed(2));
    }
  }
};