import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActivationRateChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null,
      colors: ['#4f77aa', '#ffa45b']
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        });
        //载入数据源
        this.chart.source(this.data);
        this.chart.legend({
          align: 'center',
          marker: {
            symbol: 'square'
          }
        });
        this.chart.tooltip({
          triggerOn: ['touchstart']
        });
        this.chart.interval().position('week*dataCnt').color('name', this.colors).adjust({
          type: 'dodge',
          marginRatio: 0.1 // 设置分组间柱子的间距
        }).size(15);
        // Step 4: 渲染图表
        this.chart.render();
      });
    }
  }
};