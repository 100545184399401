import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/rem';
import request from './utils/request-wxwork';
import './styles/wxwork.less';
import { Icon, Lazyload } from 'vant';

import { getQueryVariable } from './utils/utils';

function saveQueryToStorage(key) {
  !sessionStorage.getItem(key) && sessionStorage.setItem(key, getQueryVariable(key));
}

//接到加密数据
saveQueryToStorage('nonce');
saveQueryToStorage('encrypt');
saveQueryToStorage('timestamp');
saveQueryToStorage('signature');

saveQueryToStorage('week');
saveQueryToStorage('projectCode');

const app = createApp(App);
app.use(Icon);
app.use(Lazyload, {
  lazyComponent: true,
});

app.config.globalProperties.$http = request();
app.use(store).use(router).mount('#app');

// new window.VConsole();
